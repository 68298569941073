<template>
  <div
    class="w-full leading-normal flex flex-col gap-y-2 md:gap-y-6 md:flex-row relative bg-white rounded-xl md:rounded-full py-3 px-3 mt-3 md:mt-0"
  >
    <PickupDeliveryToggle
      v-if="env.STORE_FEATURES.IN_STORE_PICKUP"
      :options="acquisitionLabels"
      :selected="menuToggleState"
      class="s-toggle--enlarged w-56 self-center inline-flex md:hidden"
      @selectAcquisitionOption="selectAcquisitionOption"
    />

    <div
      v-if="env.STORE_FEATURES.IN_STORE_PICKUP"
      class="border-b md:border-b-0 pb-2 md:pb-0 md:border-r-2 hidden md:flex"
    >
      <select
        v-model="menuToggleState"
        class="s-select s-select-acquisition rounded-3xl md:rounded-none md:rounded-l-full w-full md:w-40 py-4 md:mr-2 px-3"
      >
        <option
          v-for="(label, i) of acquisitionLabels"
          :key="label"
          :value="i"
        >
          {{ label }}
        </option>
      </select>
    </div>

    <div class="flex-grow md:ml-2">
      <!-- Delivery Selector -->
      <template v-if="menuDeliverySelected">
        <GoogleAddressLookup
          v-if="!xUserHasAddress"
          class="s-select s-location rounded-3xl md:rounded-none md:rounded-r-full py-4 w-full md:pl-4"
          @error="newAddressError"
        />
        <select
          v-else
          v-model="addressOption"
          class="s-select s-select-location rounded-3xl md:rounded-none md:rounded-r-full py-4 w-full md:pl-4"
        >
          <option
            v-for="(place, index) in availablePlaces"
            :key="place.address_id"
            :value="index"
          >
            {{ _address(place) }}
          </option>
        </select>
      </template>

      <!-- Pickup Selector -->
      <template v-if="menuPickupSelected">
        <select
          v-model="pickupOption"
          class="s-select s-select-location rounded-3xl md:rounded-none md:rounded-r-full py-4 w-full md:pl-4"
        >
          <option
            v-for="(place, index) in availablePlaces"
            :key="place.id"
            :value="index"
          >
            {{ place.name }}{{ place.address.street_1 ? ` - ${place.address.street_1}` : '' }}
          </option>
        </select>
      </template>
    </div>

    <SalveButton
      class="md:w-48 md:absolute s-container-btn"
      @click.prevent="orderBtnClicked"
    >
      Order Now
    </SalveButton>
  </div>
</template>

<script setup>
  import env from '@/environment'
</script>

<script>
  import _address from '@/utils/filters/address'
  import LocationSelectionMixin from '@/mixins/LocationSelection'

  export default {
    name: 'HomePageSelectLocation',

    mixins: [LocationSelectionMixin],

    data() {
      return {
        addressOption: 0,
        pickupOption: 0
      }
    },

    computed: {
      canOrder() {
        return this.menuDeliverySelected ? this.xSelectedAddress : true
      }
    },

    watch: {
      selectedAcquisitionMethod(selectedMethod) {
        this.menuToggleState = selectedMethod
      },
      xSelectedAddress() {
        this.instantiateDropdownStates()
      },
      xSelectedPickupLocation() {
        this.instantiateDropdownStates()
      }
    },

    created() {
      // The global delivery / pickup state should be used to instantiate this component's internal state.
      this.instantiateDropdownStates()
    },

    methods: {
      _address,

      instantiateDropdownStates() {
        const selected = Math.max(0, this.selectedPlace)
        this.addressOption = selected
        this.pickupOption = selected
      },

      async orderBtnClicked() {
        const selectedLocation = this.availablePlaces[this.menuPickupSelected ? this.pickupOption : this.addressOption]

        console.log(selectedLocation)

        if (!selectedLocation) {
          this.$emit('openRegionModal')
          return
        }

        const proceed = await this.selectPlace(selectedLocation)
        if (!proceed) return

        if (this.menuDeliverySelected) {
          await this.$router.push('/shop')
        } else {
          await this.$router.push('/shop')
        }
      },

      newAddressError() {
        alert('Address Error')
      }
    }
  }
</script>

<style scoped lang="postcss">
  $chevron-ico-size: theme('spacing.3');
  $circle-ico-size: theme('spacing.10');

  $button-width: theme('spacing.48');
  $button-height: 58px;

  :deep(.s-select) {
    background-image: url('assets/icon/svg/chevron.svg');
    background-position: calc(100% - $chevron-ico-size) center;
    background-repeat: no-repeat;
    background-size: $chevron-ico-size;
    @apply font-medium text-base md:text-lg appearance-none overflow-hidden whitespace-nowrap overflow-ellipsis bg-white;
  }

  :deep(.s-select-acquisition) {
    background-image: url('assets/icon/svg/chevron.svg'), url('assets/icon/svg/circle-bag.svg');
    background-position: calc(100% - $chevron-ico-size) center, theme('spacing.2') center;
    background-size: $chevron-ico-size, $circle-ico-size;
    @apply pl-14 md:pl-8;
    @screen md {
      background-image: url('assets/icon/svg/chevron.svg');
      background-position: calc(100% - $chevron-ico-size) center;
      background-size: $chevron-ico-size;
    }
  }

  :deep(.s-location) {
    background-image: url('assets/icon/svg/map-pin.svg');
    background-position: theme('spacing.2') center;
    background-size: $circle-ico-size;
    @apply pl-14 pr-3;
  }

  :deep(.s-select-location) {
    background-image: url('assets/icon/svg/chevron.svg'), url('assets/icon/svg/circle-map-pin.svg');
    background-position: calc(100% - $chevron-ico-size) center, theme('spacing.2') center;
    background-size: $chevron-ico-size, $circle-ico-size;
    @apply pl-14 pr-14;

    @screen md {
      background-position: calc(100% - ($button-width + theme('spacing.5'))) center, theme('spacing.2') center;
      padding-right: calc($button-width + theme('spacing.8') + $chevron-ico-size);
    }
  }

  .s-container-btn {
    height: $button-height;
    top: 13px;
    right: 13px;
    animation: wheelHueColor 10s infinite;
    background-color: currentColor;
    @keyframes wheelHueColor {
      from,
      to {
        background-color: hsl(324, 70%, 45%);
      }
      10% {
        background-color: hsl(360, 65%, 45%);
      }
      20% {
        background-color: hsl(36, 80%, 45%);
      }
      30% {
        background-color: hsl(72, 75%, 35%);
      }
      40% {
        background-color: hsl(108, 65%, 35%);
      }
      50% {
        background-color: hsl(144, 75%, 35%);
      }
      60% {
        background-color: hsl(180, 75%, 35%);
      }
      70% {
        background-color: hsl(216, 60%, 45%);
      }
      80% {
        background-color: hsl(252, 65%, 50%);
      }
      90% {
        background-color: hsl(288, 60%, 40%);
      }
    }
  }
</style>
